import { ContactUs } from '@/Contact-Us';
import { ScheduleYourFirstVisit } from '@/Schedule-Your-First-Visit';
import { Nav } from '@/layout/Nav';
import { Footer } from '@/layout/Footer';
import React from 'react';
import { InsurancePlans } from '@/Insurance-Plans';

export const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <main>
            <Nav />
            {children}
            <InsurancePlans />
            <ScheduleYourFirstVisit />
            <ContactUs />
            <Footer />
        </main>
    );
};
