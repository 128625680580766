import styled from '@emotion/styled';
import { theme } from '@/layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { LocationLink } from './Location-Link';
import { Link } from 'gatsby';
import { ReactComponent as Facebook } from '../../images/svgs/facebook.svg';
import { ReactComponent as Instagram } from '../../images/svgs/instagram.svg';
import { ReactComponent as Twitter } from '../../images/svgs/twitter.svg';
import { ReactComponent as Yelp } from '../../images/svgs/yelp.svg';
import { ReactComponent as Youtube } from '../../images/svgs/youtube.svg';

const FooterStyled = styled.footer`
    background: ${theme.colors.green5};
    color: ${theme.colors.gray1};
    padding: 32px 0 16px;
    .large-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    span,
    a {
        color: ${theme.colors.gray1};
    }
    .location {
        text-decoration: underline;
    }
    h3 {
        font-weight: 600;
        color: ${theme.colors.green2};
        margin-bottom: 24px;
    }

    .logo {
        width: 290px;
        margin-bottom: 40px;
    }
    svg {
        fill: ${theme.colors.gray1};
    }
    @media ${theme.device.tablet} {
        .main-flex {
            display: flex;
            gap: 64px;
        }
    }
    @media ${theme.device.laptop} {
        .main-flex {
            display: flex;
            gap: auto;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 72px;
        }
        h3 {
            font-size: 24px;
            line-height: 35px;
            margin-bottom: 40px;
        }
        .logo {
            width: 553px;
        }
    }
`;

const ConnectUs = styled.div`
    align-self: start;
    a:first-of-type {
        margin-bottom: 20px;
        max-width: 290px;
    }
`;

const Navigations = styled.div`
    align-self: start;
    svg {
        fill: ${theme.colors.gray1};
    }
    .list-flex {
        display: flex;
        gap: 37px;
        margin-bottom: 32px;

        ul {
            list-style: none;
        }
        li {
            margin-bottom: 24px;
        }
    }
    @media ${theme.device.laptop} {
        .list-flex {
            margin-bottom: 0;
        }
    }
`;

const FollowUs = styled.div`
    align-self: start;
    display: flex;
    gap: 32px;
    margin-bottom: 40px;
    span {
        color: ${theme.colors.gray1};
    }
    @media ${theme.device.laptop} {
        margin-bottom: 00px;
    }
`;

export const Footer: React.FC = () => {
    return (
        <FooterStyled>
            <div className="large-container">
                <StaticImage
                    className="logo"
                    src="../../images/common/logo.png"
                    alt="first care dental logo"
                    placeholder="none"
                    quality={100}
                />
                <div className="main-flex">
                    <div>
                        <ConnectUs>
                            <h3>Connect Us</h3>
                            <LocationLink
                                border
                                dark
                                className="first-location location"
                                type="map"
                            />
                            <LocationLink
                                border
                                dark
                                className="second-location location"
                                type="phone"
                            />
                        </ConnectUs>
                        <FollowUs>
                            <span>Follow Us:</span>
                            <a
                                aria-label="facebook logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/FirstCareDentalAssociates/"
                            >
                                <Facebook role="img">
                                    <title>Facebook</title>
                                </Facebook>
                            </a>
                            <a
                                aria-label="instagram logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/tallydentist/"
                            >
                                <Instagram role="img">
                                    <title>Instagram</title>
                                </Instagram>
                            </a>
                            <a
                                aria-label="twitter logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/TallyDentist"
                            >
                                <Twitter role="img">
                                    <title>Twitter</title>
                                </Twitter>
                            </a>
                            <a
                                aria-label="yelp logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.yelp.com/biz/first-care-dental-associates-tallahassee"
                            >
                                <Yelp role="img">
                                    <title>Yelp</title>
                                </Yelp>
                            </a>
                            <a
                                aria-label="youtube logo"
                                target="__blank"
                                rel="noopener noreferrer"
                                href="https://www.youtube.com/channel/UCmeE0lsqalbCyBIk3RnMfXQ"
                            >
                                <Youtube role="img">
                                    <title>Youtube</title>
                                </Youtube>
                            </a>
                        </FollowUs>
                    </div>
                    <Navigations>
                        <h3>Navigations</h3>
                        <div className="list-flex">
                            <ul>
                                <li>
                                    <Link to="/our-services/general-and-family-dentistry/dental-implants/">
                                        Dental Implants
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/our-services/cosmetic-dentistry/">
                                        Cosmetic Dentistry
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/our-services/general-and-family-dentistry/root-canal-therapy/">
                                        Root Canals
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/our-services/general-and-family-dentistry/periodontal-disease-therapy/">
                                        Periodontics
                                    </Link>
                                </li>
                                <li></li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to="//our-services/general-and-family-dentistry/">
                                        Family Dentistry
                                    </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/our-services/general-and-family-dentistry/oral-hygiene-instruction/">
                                        Oral Hygiene
                                    </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/our-services/cosmetic-dentistry/porcelain-dental-veneers/">
                                        Veneers
                                    </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/our-services/general-and-family-dentistry/dental-fillings/">
                                        Dental Fillings
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Navigations>
                </div>
            </div>
            <p style={{ color: '#fcfcfc', margin: '0 auto', textAlign: 'center' }}>
                © 2023 FIRST CARE DENTAL ASSOCIATES
            </p>
        </FooterStyled>
    );
};
