import styled from '@emotion/styled';
import { theme } from '@/layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { ButtonPrimary } from './layout/StyledComponents';

const ScheduleYourFirstVisitStyled = styled.div`
    padding-bottom: 64px;
    .image {
        height: 343px;
        margin-bottom: 24px;
    }

    h2 {
        margin-bottom: 16px;
    }
    @media ${theme.device.laptop} {
        padding-bottom: 80px;
        display: flex;
        align-items: center;
        gap: 32px;
        h2 {
            text-align: left;
            max-width: 85%;
        }
        button {
            width: 230px;
        }
        .image {
            flex-shrink: 0;
            width: 540px;
            height: 840px;
        }
    }
    @media ${theme.device.laptopL} {
        padding-bottom: 80px;
        display: flex;
        align-items: center;
        gap: 85px;
        .image {
            width: 717px;
            height: 617px;
        }
        h2 {
            text-align: left;
            max-width: 100%;
        }
    }
`;

const Form = styled.form`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    input,
    textarea {
        border: none;
        outline: 2px solid ${theme.colors.gray2};
        padding: 15px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    button {
        width: 100%;
    }
    button:first-of-type {
        margin-bottom: 24px;
    }
    input:focus,
    textarea:focus {
        border: none;
        outline: 2px solid ${theme.colors.green3};
    }
    input[type='date'] {
        border: none;

        padding: 15px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
        border: none;
        width: 145px;
    }
    input[type='time'] {
        border: none;
        width: 145px;
        padding: 12px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
    }

    ::-webkit-input {
        border: none;
        outline: 2px solid ${theme.colors.gray1};
        padding: 15px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
    }

    label {
        font-weight: 500;
        font-family: 'poppins';

        display: flex;
        flex-direction: column;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    input,
    textarea {
        margin-top: 10px;
        margin-bottom: 16px;
    }
    textarea {
        margin-bottom: 24px;
        max-width: 345px;
    }
    ::-webkit-input-placeholder {
        color: inherit;
        opacity: 0.54;
    }
    @media ${theme.device.tablet} {
        textarea {
            margin-bottom: 24px;
            min-width: 685px;
            max-width: 685px;
        }
        input[type='date'],
        input[type='time'] {
            width: 314px;
        }
    }
    @media ${theme.device.laptop} {
        display: flex;
        flex-direction: column;
        width: 464px;
        textarea {
            height: 80px;
        }
        textarea {
            height: 125px;
            max-width: 426px;
            min-width: 426px;
        }
        input {
            width: 424px;
            margin-bottom: 16px;
        }

        .flex {
            input[type='date'] {
                width: 190px;
            }
            input[type='time'] {
                width: 190px;
            }
        }
    }
`;

export const ScheduleYourFirstVisit: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <ScheduleYourFirstVisitStyled id="contact" className="large-container">
            <StaticImage
                src="../images/1-homepage/4-dental-office.jpg"
                alt="dental office"
                className="image"
                placeholder="none"
                quality={100}
            />
            <div className="copy-container">
                <h2 className="header-2">Schedule Your Visit</h2>

                <Form onSubmit={onSubmit}>
                    <div>
                        <div>
                            <label htmlFor="name">
                                Name *
                                <input required id="name" name="name" type="text" />
                            </label>
                        </div>
                        <div>
                            <label htmlFor="email">
                                Email address *
                                <input
                                    placeholder="johndoe@gmail.com"
                                    required
                                    id="email"
                                    name="email"
                                    type="email"
                                />
                            </label>
                        </div>
                        <div>
                            <label htmlFor="phone">
                                Phone number *
                                <input
                                    required
                                    placeholder="012-345-678"
                                    id="phone"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    name="phone"
                                    type="tel"
                                />
                            </label>
                        </div>
                    </div>
                    <div>
                        <div className="flex">
                            <div>
                                <label htmlFor="preferred-date">Preferred Date *</label>
                                <input
                                    required
                                    id="preferred-date"
                                    name="preferred-date"
                                    type="date"
                                />
                            </div>
                            <div>
                                <label htmlFor="preferred-time">Preferred Time *</label>
                                <input
                                    required
                                    id="preferred-time"
                                    name="preferred-time"
                                    type="time"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="message">Message</label>
                            <textarea id="message" name="message" />
                        </div>
                    </div>

                    <div className="button-container">
                        {submitState === 'submitted' ? (
                            <ButtonPrimary color="green" disabled>
                                Message Sent!
                            </ButtonPrimary>
                        ) : (
                            <ButtonPrimary
                                type="submit"
                                disabled={submitState === 'submitting'}
                                color="green"
                                fill
                            >
                                {submitState === 'submitting'
                                    ? 'Sending Message...'
                                    : 'Submit Request'}
                            </ButtonPrimary>
                        )}
                    </div>
                </Form>
            </div>
        </ScheduleYourFirstVisitStyled>
    );
};
