exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-alt-template-tsx-content-file-path-src-content-general-about-us-index-mdx": () => import("./../../../src/templates/alt-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/general/about-us/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-tsx-content-file-path-src-content-general-about-us-index-mdx" */),
  "component---src-templates-alt-template-tsx-content-file-path-src-content-general-our-services-index-mdx": () => import("./../../../src/templates/alt-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/general/our-services/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-tsx-content-file-path-src-content-general-our-services-index-mdx" */),
  "component---src-templates-alt-template-tsx-content-file-path-src-content-general-patient-resources-index-mdx": () => import("./../../../src/templates/alt-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/general/patient-resources/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-tsx-content-file-path-src-content-general-patient-resources-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-about-us-meet-our-doctors-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/about-us/meet-our-doctors/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-about-us-meet-our-doctors-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-about-us-meet-our-staff-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/about-us/meet-our-staff/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-about-us-meet-our-staff-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-about-us-virtual-tour-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/about-us/virtual-tour/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-about-us-virtual-tour-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-avoid-teeth-whitening-copy-2-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/avoid-teeth-whitening copy 2/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-avoid-teeth-whitening-copy-2-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-avoid-teeth-whitening-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/avoid-teeth-whitening/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-avoid-teeth-whitening-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-boost-your-smile-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/boost-your-smile/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-boost-your-smile-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-care-for-custom-mouth-guard-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/care-for-custom-mouth-guard/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-care-for-custom-mouth-guard-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-cleaning-and-maintaining-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/cleaning-and-maintaining/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-cleaning-and-maintaining-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-dental-implants-for-anchoring-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/dental-implants-for-anchoring/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-dental-implants-for-anchoring-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-different-mouth-guards-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/different-mouth-guards/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-different-mouth-guards-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-mouth-guards-right-for-you-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/mouth-guards-right-for-you/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-mouth-guards-right-for-you-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-oral-lichen-planus-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/oral-lichen-planus/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-oral-lichen-planus-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-removing-foreign-objects-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/removing-foreign-objects/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-removing-foreign-objects-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-smoking-habit-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/smoking-habit/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-smoking-habit-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-teeth-whitening-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/teeth-whitening/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-teeth-whitening-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-three-reasons-to-visit-dentist-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/three-reasons-to-visit-dentist/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-three-reasons-to-visit-dentist-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-what-to-expect-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/what-to-expect/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-what-to-expect-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-what-you-need-to-know-about-tooth-reshaping-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/blog/what-you-need-to-know-about-tooth-reshaping/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-blog-what-you-need-to-know-about-tooth-reshaping-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-patient-resources-financial-resources-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/patient-resources/financial-resources/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-patient-resources-financial-resources-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-patient-resources-new-patient-paperwork-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/patient-resources/new-patient-paperwork/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-patient-resources-new-patient-paperwork-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-botox-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/botox/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-botox-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-cosmetic-dentistry-two-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/cosmetic-dentistry-two/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-cosmetic-dentistry-two-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-cosmetic-tooth-conotouring-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/cosmetic-tooth-conotouring/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-cosmetic-tooth-conotouring-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-bridges-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/dental-bridges/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-bridges-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-cleanings-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/dental-cleanings/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-cleanings-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-fillings-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/dental-fillings/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-fillings-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-implants-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/dental-implants/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-implants-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-sealings-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/dental-sealings/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-sealings-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-x-rays-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/dental-x-rays/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-dental-x-rays-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-general-family-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/general-family/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-general-family-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-mouth-guards-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/mouth-guards/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-mouth-guards-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-oral-cancer-screenings-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/oral-cancer-screenings/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-oral-cancer-screenings-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-oral-hygiene-instruction-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/oral-hygiene-instruction/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-oral-hygiene-instruction-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-periodontial-disease-therapy-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/periodontial-disease-therapy/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-periodontial-disease-therapy-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-porcelain-dental-crowns-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/porcelain-dental-crowns/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-porcelain-dental-crowns-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-porcelain-dental-veneers-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/porcelain-dental-veneers/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-porcelain-dental-veneers-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-root-canal-therapy-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/root-canal-therapy/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-root-canal-therapy-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-teeth-whitening-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/teeth-whitening/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-teeth-whitening-index-mdx" */),
  "component---src-templates-standard-template-tsx-content-file-path-src-content-services-tooth-extraction-index-mdx": () => import("./../../../src/templates/standard-template.tsx?__contentFilePath=/home/runner/work/first-care-dental/first-care-dental/src/content/services/tooth-extraction/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-tsx-content-file-path-src-content-services-tooth-extraction-index-mdx" */)
}

