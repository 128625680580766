import React from 'react';
import styled from '@emotion/styled';
import { theme } from '@/layout/GlobalStyles';
import { LocationLink } from './layout/Location-Link';

const SchedulePanel = styled.div`
    background: ${theme.colors.green1};
    padding: 64px 0;
    iframe {
        height: 348.71px;
        margin-bottom: 24px;
    }
    h2 {
        margin-bottom: 24px;
    }
    a {
        color: ${theme.colors.gray5};
        margin-bottom: 24px;
    }
    @media ${theme.device.tablet} {
        .large-container {
            justify-content: space-between;
            display: flex;
            gap: 32px;
        }
        iframe {
            width: 360px;
            height: 430px;
        }
    }
    @media ${theme.device.laptop} {
        padding: 80px 0;
        .large-container {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
        .first-location {
            min-width: 500px;
        }
        .second-location {
            width: 200px;
            margin-bottom: 60px;
        }
        h2 {
            max-width: 400px;
            margin-bottom: 60px;
            text-align: left;
        }

        iframe {
            width: 632px;
            margin-top: 32px;
            height: 633.79px;
        }
        .flex {
            max-width: 404px;
            display: block;
        }
    }
`;

const Hours = styled.div`
    margin: 0;
    display: flex;
    justify-content: space-between;
    ul {
        list-style: none;
        span {
            display: block;
            color: ${theme.colors.gray4};
        }
        li {
            margin: 0px 0px 16px;
        }
    }
    ul:first-of-type {
        font-weight: 700;

        text-align: left;
    }
    ul:last-of-type {
        text-align: right;
    }
    @media ${theme.device.tablet} {
    }
    @media ${theme.device.laptop} {
        width: 100%;
    }
`;

export const ContactUs = () => {
    return (
        <SchedulePanel>
            <div className="large-container">
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13753.764924362797!2d-84.2388062!3d30.4802611!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88ec5f3ab584375d%3A0x30748933dfbe810b!2sFirst%20Care%20Dental!5e0!3m2!1sen!2sie!4v1683820951323!5m2!1sen!2sie"
                        width="100%"
                        loading="lazy"
                        title="Clermont Kids"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        aria-hidden="false"
                    />
                </div>
                <div>
                    <h2 className="header-2">Contact Us</h2>
                    <div className="flex">
                        <LocationLink border className="first-location location" type="map" />
                        <LocationLink border className="second-location location" type="phone" />
                        <Hours className="hours-container">
                            <ul>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>Saturday</li>
                                <li>Sunday</li>
                            </ul>
                            <ul>
                                <li>8:00 AM - 4:30 PM</li>
                                <li>8:00 AM - 4:30 PM</li>
                                <li>8:00 AM - 4:30 PM</li>
                                <li>8:00 AM - 4:30 PM</li>
                                <li>8:00 AM - 4:30 PM</li>
                                <li>Closed</li>
                                <li>Closed</li>
                            </ul>
                        </Hours>
                    </div>
                </div>
            </div>
        </SchedulePanel>
    );
};
