import styled from '@emotion/styled';
import { ButtonPrimary, SecondaryButton } from './layout/StyledComponents';
import bg from '../images/common/insurance-background-dark.webp';
import { ReactComponent as Delta } from '../images/svgs/delta-dental-logo.svg';
import { ReactComponent as BlueCross } from '../images/svgs/blue-cross-logo.svg';
import { ReactComponent as Aetna } from '../images/svgs/aetna-logo.svg';
import { ReactComponent as Principal } from '../images/svgs/principal-logo.svg';

const InsurancePlansStyled = styled.div`
    background: url(${bg}) no-repeat center;
    margin-bottom: 64px;
    .copy-container {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            color: ${({ theme }) => theme.colors.gray1};
            text-align: left;
            margin-bottom: 24px;
        }
        p {
            color: ${({ theme }) => theme.colors.gray2};
            margin-bottom: 0px;
        }
        button {
            margin-top: 24px;
        }
    }
    .logos-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
            margin-bottom: 41px;
        }
        .delta {
            width: 277px;
        }
        .blue-cross {
            width: 216px;
        }
        .aetna {
            width: 137px;
        }
        .principal {
            width: 152px;
        }
    }
    @media ${({ theme }) => theme.device.tablet} {
        .logos-container {
            flex-direction: row;
            gap: 24px;
            padding-bottom: 72px;
        }
        .copy-container {
            align-items: flex-end;
            padding-top: 72px;
        }
    }
    @media ${({ theme }) => theme.device.laptop} {
        margin-bottom: 80px;
        .outter-container {
            max-width: 1020px;
            margin: 0 auto;
            display: flex;

            gap: 140px;
            padding: 120px 0 40px;
        }
        .copy-container {
            position: relative;
            bottom: 70px;
            flex-shrink: 0;
            align-items: flex-start;
            width: 541px;
            button {
                margin-bottom: 0;
            }
        }
        .logos-container {
            gap: 54px;
            flex-direction: column;
            align-items: flex-start;
            svg {
                margin-bottom: 0;
            }
            .delta {
                width: 494px;
            }
            .blue-cross {
                width: 238px;
            }
            .aetna {
                width: 197px;
            }
            .principal {
                width: 173px;
            }
        }
    }
    @media ${({ theme }) => theme.device.laptopL} {
        .outter-container {
            max-width: 1200px;
        }
        .logos-container {
            gap: 64px;
        }
        .copy-container {
            width: 641px;
        }
    }
`;

export const InsurancePlans: React.FC = () => {
    return (
        <InsurancePlansStyled>
            <div className="outter-container">
                <div className="large-container copy-container">
                    <h2 className="header-2">We accept several dental insurance plans</h2>
                    <p>
                        {`We accept a wide range of dental insurances, ensuring your smiles stay
                        bright! Don't have coverage? Call us! We can explore alternative options.
                        We're here to help navigate financial details, making dental care accessible
                        and stress-free. Don't let finances hinder your oral health!"`}
                    </p>
                    <a
                        href="https://firstcaredental.launchsavings.com/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <ButtonPrimary fill>Sign Up Our In-House Dental Plan</ButtonPrimary>
                    </a>
                    <SecondaryButton link="/patient-resources/financial-resources/" color="yellow">
                        Explore all financial options
                    </SecondaryButton>
                </div>
                <div className="large-container logos-container">
                    <Delta className="delta" />
                    <BlueCross className="blue-cross" />
                    <Aetna className="aetna" />
                    <Principal className="principal" />
                </div>
            </div>
        </InsurancePlansStyled>
    );
};
