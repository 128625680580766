export const navMenu = [
    {
        name: 'About Us',
        path: '/about-us/',
        menu: [
            { name: 'Meet Our Doctors', path: '/about-us/meet-our-doctors/' },
            { name: 'Meet Our Staff', path: '/about-us/meet-our-staff/' },
            { name: 'Virtual Tour', path: '/about-us/virtual-tour/' },
        ],
    },
    {
        name: 'Our Services',
        path: '/our-services/',
        menu: [
            {
                name: 'General & Family Dentistry',
                path: '/our-services/general-and-family-dentistry/',
                subMenu: [
                    {
                        name: 'Dental Bridges',
                        path: '/our-services/general-and-family-dentistry/dental-bridge/',
                    },
                    {
                        name: 'Dental Cleanings',
                        path: '/our-services/general-and-family-dentistry/dental-cleanings/',
                    },
                    {
                        name: 'Dental Fillings',
                        path: '/our-services/general-and-family-dentistry/dental-fillings/',
                    },
                    {
                        name: 'Dental Implants',
                        path: '/our-services/general-and-family-dentistry/dental-implants/',
                    },
                    {
                        name: 'Dental Sealants',
                        path: '/our-services/general-and-family-dentistry/dental-sealants/',
                    },
                    {
                        name: 'Dental X-Rays',
                        path: '/our-services/general-and-family-dentistry/dental-x-rays/',
                    },
                    {
                        name: 'Mouth Guards',
                        path: '/our-services/general-and-family-dentistry/mouth-guards/',
                    },
                    {
                        name: 'Oral Cancer Screening',
                        path: '/our-services/general-and-family-dentistry/oral-cancer-screenings/',
                    },
                    {
                        name: 'Oral Hygiene Instruction',
                        path: '/our-services/general-and-family-dentistry/oral-hygiene-instruction/',
                    },
                    {
                        name: 'Periodontal Disease Therapy',
                        path: '/our-services/general-and-family-dentistry/periodontal-disease-therapy/',
                    },
                    {
                        name: 'Porcelain Dental Crowns',
                        path: 'our-services/general-and-family-dentistry/porcelain-dental-crowns/',
                    },
                    {
                        name: 'Root Canal Therapy',
                        path: '/our-services/general-and-family-dentistry/root-canal-therapy/',
                    },
                    {
                        name: 'Tooth Extraction',
                        path: '/our-services/general-and-family-dentistry/tooth-extraction/',
                    },
                ],
            },
            {
                name: 'Cosmetic Dentistry',
                path: '/our-services/cosmetic-dentistry/',
                subMenu: [
                    {
                        name: 'BOTOX®',
                        path: '/our-services/cosmetic-dentistry/botox/',
                    },
                    {
                        name: 'Cosmetic Tooth Contouring',
                        path: '/our-services/cosmetic-dentistry/cosmetic-tooth-contouring/',
                    },
                    {
                        name: 'Porcelain Dental Veneers',
                        path: '/our-services/cosmetic-dentistry/porcelain-dental-veneers/',
                    },
                    {
                        name: 'Teeth Whitening',
                        path: '/our-services/cosmetic-dentistry/teeth-whitening/',
                    },
                ],
            },
        ],
    },
    {
        name: 'Patient Resources',
        path: '/patient-resources/',
        menu: [
            {
                name: 'Financial Resources',
                path: '/patient-resources/financial-resources/',
            },
            { name: 'New Patient Paperwork', path: '/patient-resources/new-patient-paperwork/' },
            { name: 'Blog', path: '/blog/' },
        ],
    },
];
