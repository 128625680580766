import { ReactComponent as Marker } from '../../images/svgs/marker.svg';
import { ReactComponent as Mobile } from '../../images/svgs/mobile.svg';

import styled from '@emotion/styled';
import { information } from 'information';

interface Props {
    dark?: boolean;
}

const LocationLinkStyled = styled.div<Props>`
    .svg-container {
        background: ${({ theme, dark }) => (dark ? theme.colors.green3 : theme.colors.green2)};
        min-width: 32px;
        height: 32px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .mobile {
            width: 12px;
        }
        svg {
            fill: ${({ dark, theme }) => dark && theme.colors.gray1};
        }
        .marker-svg-border {
            width: 9px;

            height: 100%;
        }
    }
    a {
        transition: all 0.2s ease-in-out;
        text-decoration: underline;
        line-height: 24px;
        display: flex;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.green3};
        align-items: center;
        .marker-svg {
            width: 17px;
        }
        svg {
            transition: all 0.2s ease-in-out;
            transform: scale(1);
            min-width: 18px;
            fill: ${({ theme }) => theme.colors.green3};
        }
        span {
            margin-left: 17px;
        }
        &:hover {
            svg {
                transform: scale(1.2);
            }
        }
    }
`;

export const LocationLink: React.FC<{
    type: 'map' | 'phone';
    className?: string;
    border?: boolean;
    dark?: boolean;
}> = ({ type, className, border, dark }) => {
    return (
        <LocationLinkStyled dark={dark} className={className}>
            {type === 'map' && (
                <a target="__blank" rel="noopener noreferrer" href={information.locationUrl}>
                    {border ? (
                        <div className="svg-container">
                            <Marker className="marker-svg-border" />
                        </div>
                    ) : (
                        <Marker className="marker-svg" />
                    )}
                    <span>{information.location}</span>
                </a>
            )}
            {type === 'phone' && (
                <a href={`tel:${information.phone}`}>
                    {border ? (
                        <div className="svg-container">
                            <Mobile className="mobile" />
                        </div>
                    ) : (
                        <Mobile className="mobile" />
                    )}
                    <span>{information.phone}</span>
                </a>
            )}
        </LocationLinkStyled>
    );
};
